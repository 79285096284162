import { DropdownData } from "@/common/types/Form";
import PageLayout from "@/components/layout/PageLayout";
import Table from "@/components/table/Table";
import { useEffect, useState } from "react";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./hunting-card-reports-fields";
import { HuntingCardReport } from "@/common/types/Reports";
import { ReportsService } from "@/common/API/ReportsService";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import useDropdownData from "@/common/hooks/useDropdownData";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import Card from "@/components/Card";
import { StatisticsData } from "@/common/types/Reports";

const HuntingCardReportsList = () => {
  const [data, setData] = useState<HuntingCardReport>();
  const [statisticsData, setStatisticsData] = useState<StatisticsData>();
  const [seasons, setSeasons] = useState<DropdownData[]>([]);

  const { loginData, isAdmin } = useAuth();
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const { associations } = useDropdownData({ hasAgency: true, query });

  const getHuntingCardReport = async () => {
    const response = await ReportsService.all_hunting_cards(
      Utils.formatQueryString(query),
    );

    const availableSeasons: DropdownData[] =
      response?.data?.data?.available_seasons.map((season: string) => ({
        name: season,
        id: season,
      }));

    if (query?.season && query?.season === "") {
      const season = availableSeasons?.[0]?.id ?? "";

      setQuery((prevState: Record<string, string>) => ({
        ...prevState,
        season: prevState?.season || String(season),
      }));
    }

    setData(response?.data?.data?.report);
    setSeasons(availableSeasons);
  };

  const getHuntingCardStatistics = async () => {
    const response = await ReportsService.get_statistics(
      Utils.formatQueryString(query),
    );

    setStatisticsData(response?.data?.data);
  };

  const isAdminOrModerator = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Moderator,
  ]);

  useEffect(() => {
    isAdmin &&
      setQuery((prevState: Record<string, string>) => ({
        ...prevState,
        association_id: String(loginData?.data?.association?.id),
      }));
  }, []);

  useEffect(() => {
    if (query?.association_id) {
      getHuntingCardReport();
      getHuntingCardStatistics();
    }
  }, [query]);
  const statisticsCards = () => {
    return (
      isAdminOrModerator && (
        <div className="mb-4 flex flex-col space-y-4">
          <div className="grid grid-cols-12 gap-4">
            <Card
              title="Broj položenih lovačkih ispita"
              content={
                statisticsData?.report?.exam_candidates_count?.length === 0 ? (
                  <span className="text-sm font-medium">
                    Nema zabeleženih podataka
                  </span>
                ) : (
                  <div className="grid grid-cols-3 gap-4">
                    {statisticsData?.report?.exam_candidates_count?.map(
                      (el, index) => (
                        <div className="col-span-1 w-full" key={index}>
                          <span className="text-sm uppercase text-gray-400">
                            {el?.year}:
                          </span>
                          <h2 className="text-xl font-medium">
                            {el?.candidates_count}
                          </h2>
                        </div>
                      ),
                    )}
                  </div>
                )
              }
              isLoading={!statisticsData}
            />
          </div>
          <div className="grid grid-cols-12 gap-4">
            <Card
              title="Ukupan broj korisnika lovišta"
              content={
                <div>
                  <h2 className="text-xl font-medium">
                    {statisticsData?.report?.organization_count}
                  </h2>
                </div>
              }
              isLoading={!statisticsData}
            />
            <Card
              title="Ukupan broj odobrenih lovnih karti"
              content={
                <div>
                  <h2 className="text-xl font-medium">
                    {statisticsData?.total_cards}
                  </h2>
                </div>
              }
              isLoading={!statisticsData}
            />
            <Card
              title="Ukupan broj lovišta"
              content={
                <div>
                  <h2 className="text-xl font-medium">
                    {statisticsData?.report?.hunting_ground_count}
                  </h2>
                </div>
              }
              isLoading={!statisticsData}
            />
            <Card
              title="Ukupan broj izdatih markica"
              content={
                <div>
                  <h2 className="text-xl font-medium">
                    {statisticsData?.report?.delivered_stamp_count}
                  </h2>
                </div>
              }
              isLoading={!statisticsData}
            />
          </div>
        </div>
      )
    );
  };
  return (
    <PageLayout title={`Izveštaji za lovne karte`}>
      <Table
        data={{ data: data?.organizations }}
        columns={tableColumns(data)}
        filterFields={filterFields(associations, seasons)}
        filterQueries={filterQueries}
        hidePagination={true}
        hideActions={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        customComponent={statisticsCards()}
      />
    </PageLayout>
  );
};

export default HuntingCardReportsList;
