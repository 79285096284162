import Table from "@/components/table/Table";
import { useEffect, useState, useRef } from "react";
import {
  tableColumns,
  filterFields,
  filterQueries,
} from "./hunting-cards-fields";
import { HuntingCardService } from "@/common/API/HuntingCardService";
import {
  HuntingCardsResponse,
  IHuntingCard,
} from "@/common/types/HuntingCards";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import useDropdownData from "@/common/hooks/useDropdownData";
import { HuntingCardOrdersService } from "@/common/API/HuntingCardOrdersService";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";

import Modal from "@/components/Modal";
import { FormikProps } from "formik";
import FormFactory from "@/components/forms/FormFactory";
import { createHunterFields} from "../hunting-card-orders/hunter-fields";
import { HunterData, IHuntingOrder } from "@/common/types/HuntingOrders";
import { CreateHunterSchema } from "@/common/schemas/Hunting";



const HuntingCardsList = () => {

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [hunter, setHunter] = useState<HunterData>();
  const [huntingCardOrder, setHuntingCardOrder] = useState<string>();
  const hunterEditFormRef = useRef<FormikProps<HunterData>>(null);

  const onEditHunter = async (formData?: HunterData) => {
    await HuntingCardOrdersService.updateHunter(huntingCardOrder, hunter?.id, formData);
    // await console.log(huntingCardOrder, hunter?.id, formData);
    setOpenEditModal(false);
    getHuntingCards();
  };

  const [data, setData] = useState<HuntingCardsResponse | []>([]);
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { organizations } = useDropdownData({ hasAgency: true, query });
  const { loginData } = useAuth();
  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Moderator,
  ]);

  const getHuntingCards = async () => {
    console.log("Query: ", query)
    const response = await HuntingCardService.all(
      Utils.formatQueryString(query),
    );

    const data = {
      data: response?.data?.data?.hunting_cards,
      pagination: response?.data?.pagination,
    };

    setData(data);
  };

  const onPrintHuntingCard = async (huntingCard: IHuntingCard) => {
    const response = await HuntingCardOrdersService.getHunterPdf(
      String(huntingCard?.hunting_card_order?.id),
      String(huntingCard?.hunter?.id),
    );

    Utils.openDocument(response?.data?.data);
  };

  useEffect(() => {
    getHuntingCards();
  }, [query]);

  return (
    <PageLayout title="Lovne karte">
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(organizations)}
        filterQueries={filterQueries}
        hideActions={false}
        hideDelete={true}
        hideEdit={!isAdmin}
        onPrint={(huntingCard) => onPrintHuntingCard(huntingCard)}
        onEdit={(data) => {
          setHunter(data.hunter);
          setHuntingCardOrder(data.hunting_card_order.id);
          setOpenEditModal(true);
        }}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
      />
      <Modal
        open={openEditModal}
        setOpen={setOpenEditModal}
        headerTitle="Izmeni lovca"
        buttonTitle="Izmeni"
        onSubmit={() => hunterEditFormRef?.current?.handleSubmit()}
        // onSubmit={() => console.log("Edited")}
        content={
          <FormFactory
            formFields={createHunterFields(hunter)}
            onSubmit={(formData) => onEditHunter(formData)}
            formRef={hunterEditFormRef}
            validationSchema={CreateHunterSchema}
          />
        }
      />
    </PageLayout>
  );
};

export default HuntingCardsList;
